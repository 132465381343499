import React, { useRef, useState } from "react";
import Layout from "@/components/Layout";
import emailjs from "@emailjs/browser";
import { AiFillCheckCircle, AiOutlineClose } from "react-icons/ai";

const ContactUs = () => {
  const form = useRef(null);
  const firstRef = useRef(null);
  const [open, setOpen] = useState(false);

  // Email validation
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const sendEmail = (e: any) => {
    e.preventDefault();

    if (error == "") {
      setOpen(!open);

      emailjs.sendForm(
        "service_ku2fhis",
        "template_8ph3wco",
        form.current!,
        "44SgHBGceuoVsG6pV"
      );
      e.target.reset();
      setMessage("");
    } else {
      setError("Unsuccesfull mail");
    }
  };

  // Email validation
  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event: any) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError("");
    }
    setMessage(event.target.value);
  };

  let firstName: any, lastName: any, phone: any;

  return (
    <Layout title="Contact us" ContactUsBtn={false}>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="lg:container overflow-hidden px-[20px] xl:px-0"
      >
        <h1 className="mb-[4rem] text-center md:text-left">Contact us</h1>
        <div className="md:mb-[4rem] p-[10px] gap-[2rem] md:grid md:w-[75%] md:grid-cols-2">
          <input
            className="rounded-sm mb-[2rem] h-[2rem] w-full focus:outline-none bg-white dark:bg-orangeDarkDark pl-[1rem] placeholder:text-black dark:placeholder:text-white placeholder:opacity-30 md:mb-0"
            type="text"
            required={true}
            ref={firstRef}
            placeholder="First Name"
            name="first_name"
          />
          <input
            className="rounded-sm mb-[2rem] h-[2rem] w-full focus:outline-none bg-white dark:bg-orangeDarkDark pl-[1rem] placeholder:text-black dark:placeholder:text-white placeholder:opacity-30 md:mb-0"
            type="text"
            required={true}
            ref={firstRef}
            placeholder="Last Name"
            name="last_name"
          />

          <div className="relative mb-[2rem] md:mb-0">
            <input
              className="rounded-sm h-[2rem] w-full focus:outline-none bg-white dark:bg-orangeDarkDark pl-[1rem] placeholder:text-black dark:placeholder:text-white placeholder:opacity-30 md:mb-0"
              type="text"
              onChange={handleChange}
              value={message}
              required={true}
              ref={firstRef}
              placeholder="Email"
              name="email"
            />
            {error == "" ? (
              <></>
            ) : (
              <p className="text-[#ff4c4c]">Invalid email</p>
            )}
          </div>
          <input
            className="rounded-sm mb-[2rem] h-[2rem] w-full focus:outline-none bg-white dark:bg-orangeDarkDark pl-[1rem] placeholder:text-black dark:placeholder:text-white placeholder:opacity-30 md:mb-0"
            type="number"
            ref={firstRef}
            required={true}
            placeholder="Phone"
            name="phone"
            pattern="[0-9]+"
          />

          {/* <input type="text" id="salary" name="salary" pattern="[0-9]+" /> */}

          <input
            className="rounded-sm mb-[2rem] h-[2rem] w-full focus:outline-none bg-white dark:bg-orangeDarkDark pl-[1rem] placeholder:text-black dark:placeholder:text-white placeholder:opacity-30 md:mb-0"
            type="text"
            ref={firstRef}
            required={true}
            placeholder="Company name"
            name="company_name"
          />
          <select
            name="interested_in"
            ref={firstRef}
            className="mb-[2rem] outline-0 rounded-sm h-[2rem] w-full bg-white dark:bg-orangeDarkDark placeholder:opacity-0 focus:outline-none md:mb-0"
            required={true}
          >
            <option value="" className="opacity-30">
              What services are you interested in?
            </option>
            <option value="System development">System development</option>
            <option value="Mobile development">Mobile development</option>
            <option value="Web development ">Web development </option>
            <option value="AI development">AI development</option>
            <option value="System administration ">
              System administration{" "}
            </option>
            <option value="Consultation">Consultation</option>
            <option value="Hardware">Hardware</option>
            <option value="UI/UX">UI/UX</option>
          </select>
        </div>
        <div className="p-[10px]">
          <textarea
            name="additional_information"
            ref={firstRef}
            id=""
            cols={30}
            rows={7}
            placeholder="Additional information"
            className="w-full bg-white rounded-sm dark:bg-orangeDarkDark p-[1rem] placeholder:text-black dark:placeholder:text-white placeholder:opacity-30 md:w-full"
          ></textarea>
        </div>
        <div className="my-[4rem] items-center text-center md:flex md:text-left">
          <p>
            O
            <span className="text-[14px]">
              R CONTACT US DIRECTLY AT
              <a href="mailto:contact@steppelink" className="font-[700]">
                {" "}
                CONTACT@STEPPELINK.MN
              </a>
            </span>
          </p>
          <button className="relative mt-[2rem] h-[4rem] bg-white px-[5rem] text-[22px] font-[600] text-orange md:mt-0 md:ml-[4rem]">
            SEND
            {error == "Unsuccesfull mail" ? (
              <div className="absolute bottom-[105%] bg-white text-orange p-[10px] rounded-lg right-[50%] translate-x-[50%]">
                <p className="font-[400]">
                  Please put valid <span className="font-[700]">email</span>
                </p>
              </div>
            ) : error == "Unsuccesfull phone" ? (
              <div className="absolute bottom-[105%] bg-white text-orange p-[10px] rounded-lg right-[50%] translate-x-[50%]">
                <p>Please put valid phone</p>
              </div>
            ) : (
              <span className="hidden"></span>
            )}
          </button>
        </div>
      </form>

      {/* Success */}
      <div
        className={`p-[2rem] duration-700 pt-[3rem] top-[50%] w-full max-w-[350px] md:w-full ease-in-out absolute -translate-y-[50%] left-[50%] -translate-x-[50%] z-40 bg-white dark:border-white border-[2px] text-black rounded-lg ${
          open == false ? "-top-[100%]" : "top-[50%]"
        }`}
      >
        <button
          onClick={() => {
            setOpen(!open);
          }}
          className="absolute top-[1rem] right-[1rem]"
        >
          <AiOutlineClose size={25} color="gray" />
        </button>
        <div className="relative text-center z-40 flex flex-col gap-[1rem]">
          <AiFillCheckCircle size={30} color="#00d736" className="mx-auto" />
          <h4>Thank you for submitting!</h4>
          <p>Our staff will be in touch with you very soon.</p>
        </div>
      </div>
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={`bg-black opacity-0 h-screen w-screen absolute z-20 ${
          open == false ? "-top-[150%]" : "top-0"
        }`}
      ></div>
    </Layout>
  );
};

export default ContactUs;
